import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Config from "../../config";
import { formWidth } from "../../theme";
import Page from "../../components/Page/Page";

export type ExpiredTokenDetailsProps = {};
const ExpiredToken: FC<ExpiredTokenDetailsProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!Config.TokenHasExpired) {
      navigate("/");
    }
  }, [Config]);

  return (
    <Page
      title={t("pages.expiredToken.title")}
      subtitle={t("pages.expiredToken.subtitle")}
      titleWidth={formWidth}
      enforceDirection="column"
      centered
    >
      <Box mt={2} mb={2} />
    </Page>
  );
};

export default ExpiredToken;
