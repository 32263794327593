import React, { FC, useContext } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import theme, { boxShadow } from "../../theme";
import { useTranslation } from "react-i18next";
import { FormControl, Link, MenuItem, Select } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { AppCtx, AppActions } from "../../store/store";

type HeaderProps = {};

const Header: FC<HeaderProps> = () => {
  const {
    state: { language },
    dispatch,
  } = useContext(AppCtx);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const handleChange = (e: any) => {
    if (e?.target?.value) {
      i18n.changeLanguage(e.target.value.toLowerCase());
      dispatch({ type: AppActions.UPDATE_LANGUAGE, payload: e.target.value });
    }
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent="space-between"
      height={30}
      padding={4}
      boxShadow={boxShadow}
      borderBottom={`1px solid ${theme.palette.primary.light}`}
    >
      <Link
        href="https://www.weselect.com/"
        underline="none"
        display="flex"
        alignItems="center"
      >
        <img
          height={42}
          width={"auto"}
          src={Logo}
          alt="logo"
          onClick={() => navigate("/")}
        />
      </Link>
      <Box display="flex" alignItems="center">
        <LanguageIcon fontSize="small" />
        <FormControl sx={{ width: 80, ml: 1 }}>
          <Select displayEmpty value={language} onChange={handleChange}>
            <MenuItem value="EN">EN</MenuItem>
            <MenuItem value="SV">SV</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Header;
