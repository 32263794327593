import React, { FunctionComponent } from "react";
import { HashRouter as Router } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import theme from "./theme";
import Routes from "./routes";
import { StateProvider } from "./store/store";
import "./App.css";

const App: FunctionComponent = () => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <MuiThemeProvider theme={theme}>
        <StateProvider>
          <Router>
            <Routes />
          </Router>
        </StateProvider>
      </MuiThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
