import React, { SyntheticEvent, useRef, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  TextField,
  InputLabel,
  Container,
  Avatar,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  OutlinedInput,
  Grid,
} from "@mui/material";
import { buildYup } from "schema-to-yup";

interface Props {
  schema: any;
  onSubmit: (values: any) => void;
}

const formatDate = (value: string) => {
  const numbers = value.replace(/\D/g, "");
  let output = numbers;

  if (numbers.length >= 3 && numbers.length <= 4) {
    output = `${numbers.slice(0, 2)}/${numbers.slice(2)}`;
  }

  if (numbers.length > 4) {
    output = `${numbers.slice(0, 2)}/${numbers.slice(2, 4)}/${numbers.slice(
      4,
      8
    )}`;
  }

  return output;
};

const DynamicForm: React.FC<Props> = ({ schema, onSubmit }) => {
  const fileInputRef = useRef<any>(null);
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files![0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setAvatarSrc(reader.result as string);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const buildInitialValues = (
    schemaProperties: any
  ): { [key: string]: any } => {
    const initialValues: { [key: string]: any } = {};

    for (const key in schemaProperties) {
      const property = schemaProperties[key];
      switch (property.type) {
        case "string":
          initialValues[key] = "";
          break;
        case "number":
          initialValues[key] = 0;
          break;
        case "boolean":
          initialValues[key] = false;
          break;
        case "radio":
          initialValues[key] = property.enum ? property.enum[0] : "";
          break;
        default:
          initialValues[key] = "";
      }
    }

    return initialValues;
  };

  const validationSchema = buildYup(schema);

  const formik = useFormik({
    initialValues: buildInitialValues(schema.properties),
    validationSchema,
    onSubmit: onSubmit,
  });

  const handleClickOnAvatar = () => {
    fileInputRef.current?.querySelector("input")?.click();
  };

  const commonProps = (key: string) => ({
    id: key,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    error: Boolean(formik.touched[key] && formik.errors[key]),
    helperText: formik.touched[key] && formik.errors[key],
  });

  const renderField = (key: string) => {
    const fieldSchema = schema.properties[key];
    const fieldTitle = fieldSchema.title;

    const RenderRadio = () => {
      return (
        <Box key={key}>
          <InputLabel>{fieldTitle}</InputLabel>
          <RadioGroup {...formik.getFieldProps(key)}>
            {fieldSchema.enum.map((option: string, index: number) => (
              <FormControlLabel
                key={index}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        </Box>
      );
    };

    switch (fieldSchema.type) {
      case "string":
        if (fieldSchema.enum) {
          return <RenderRadio />;
        }

        if (key === "profilePic") {
          return (
            <Box
              key={key}
              onClick={handleClickOnAvatar}
              style={{ cursor: "pointer" }}
            >
              <Avatar
                variant="square"
                style={{ width: "100%", height: "100%", maxHeight: 180 }}
                src={avatarSrc || undefined}
              />
              <InputLabel htmlFor={key}>{fieldTitle}</InputLabel>
              <OutlinedInput
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                {...commonProps(key)}
                onChange={handleFileChange}
              />
            </Box>
          );
        }
        if (key === "dateOfBirth") {
          return (
            <Box key={key} style={{ width: "100%" }}>
              <InputLabel htmlFor={key}>{fieldTitle}</InputLabel>
              <TextField
                {...commonProps(key)}
                variant="outlined"
                type="text"
                placeholder="MM/DD/YYYY"
                value={formik.values.dateOfBirth}
                onChange={(
                  e: SyntheticEvent & { target: { value: string } }
                ) => {
                  e.target.value = formatDate(e.target.value);
                  formik.setFieldValue("dateOfBirth", e.target.value);
                }}
                name={key}
                fullWidth
              />
            </Box>
          );
        }

        return (
          <Box key={key}>
            <InputLabel htmlFor={key}>{fieldTitle}</InputLabel>
            <TextField
              variant="outlined"
              {...formik.getFieldProps(key)}
              {...commonProps(key)}
            />
          </Box>
        );
      case "number":
        return (
          <Box key={key}>
            <InputLabel>{fieldTitle}</InputLabel>
            <TextField
              variant="outlined"
              type="number"
              {...formik.getFieldProps(key)}
              {...commonProps(key)}
            />
          </Box>
        );
      case "boolean":
        return (
          <Box key={key}>
            <InputLabel>{fieldTitle}</InputLabel>
            <Checkbox {...formik.getFieldProps(key)} {...commonProps(key)} />
          </Box>
        );
      case "radio":
        return (
          <Box key={key}>
            <InputLabel>{fieldTitle}</InputLabel>
            <RadioGroup {...formik.getFieldProps(key)}>
              {fieldSchema.enum.map((option: string, index: number) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Container component="main">
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid container item xs={12} sm={8} spacing={2}>
            {[
              "firstName",
              "lastName",
              "email",
              "phoneNumber",
              "dateOfBirth",
            ].map((key) => (
              <Grid item xs={12} sm={6} key={key}>
                {renderField(key)}
              </Grid>
            ))}
          </Grid>
          <Grid container item xs={12} sm={4} p={2}>
            <Box
              display="flex"
              border="1px solid lightgray"
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              p={2}
              boxSizing="border-box"
              borderRadius={2}
            >
              {renderField("profilePic")}
            </Box>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            {Object.keys(schema.properties)
              .filter(
                (key) =>
                  ![
                    "firstName",
                    "lastName",
                    "email",
                    "phoneNumber",
                    "dateOfBirth",
                    "profilePic",
                  ].includes(key)
              )
              .map((key) => (
                <Grid item xs={12} sm={6} key={key}>
                  {renderField(key)}
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: 40 }}
          onClick={() => formik.handleSubmit()}
        >
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default DynamicForm;
