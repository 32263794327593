import React, { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Theme, useMediaQuery } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

type PageProps = {
  title?: string;
  subtitle?: string;
  titleWidth?: number;
  centered?: boolean;
  enforceDirection?: "row" | "column";
  FooterContent?: FC<any>;
  hideHeader?: boolean;
};

const Page: FC<PageProps> = ({
  children,
  title,
  subtitle,
  titleWidth,
  centered,
  enforceDirection,
  FooterContent,
  hideHeader,
}) => {
  const theme = useTheme();
  const showMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <Box display={"flex"} flexDirection={"column"} height={"100%"}>
      {!hideHeader && <Header />}
      <Box
        display={"flex"}
        flex={1}
        flexDirection={"column"}
        alignItems={"center"}
        sx={{
          backgroundColor: theme.palette?.background.default,
          overflowY: "auto",
        }}
      >
        <Box
          display={"flex"}
          flex={1}
          width={"100%"}
          flexDirection={enforceDirection || (showMobile ? "column" : "row")}
          alignItems={"center"}
          justifyContent={centered ? "center" : undefined}
        >
          {title && (
            <Box
              mt={1}
              p={4}
              pb={subtitle ? 2 : 4}
              pt={0}
              maxWidth={titleWidth || "100%"}
            >
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                textAlign={"center"}
              >
                {title}
              </Typography>
            </Box>
          )}
          {title && subtitle && (
            <Box mb={2} maxWidth={titleWidth || "100%"}>
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                textAlign={"center"}
              >
                {subtitle}
              </Typography>
            </Box>
          )}
          {children}
        </Box>
      </Box>
      {FooterContent ? (
        <Footer>
          <FooterContent />
        </Footer>
      ) : null}
    </Box>
  );
};

export default Page;
