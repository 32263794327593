import { FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Config from "../../config";
import { AppCtx, AppActions } from "../../store/store";
import Page from "../../components/Page/Page";
import { useTranslation } from "react-i18next";
import DynamicForm from "../../components/DynamicForm/DynamicForm";

const schema = {
  type: "object",
  properties: {
    firstName: { type: "string", title: "First Name" },
    lastName: { type: "string", title: "Last Name" },
    email: { type: "string", title: "Email", format: "email" },
    phoneNumber: {
      type: "string",
      pattern: "^[0-9]{10}$",
      title: "Phone Number",
    },
    dateOfBirth: { type: "string", format: "date", title: "Date of Birth" },
    description: { type: "string", title: "Description" },
    gender: {
      type: "string",
      enum: ["Male", "Female", "Other"],
      title: "Gender",
    },
    profilePic: {
      type: "string",
      format: "data-url",
      title: "Profile Picture",
    },
    agreeTerms: { type: "boolean", title: "Agree to Terms" },
  },
  required: ["firstName", "lastName", "email", "phoneNumber", "dateOfBirth"],
};

export type HomeProps = {};
const Home: FC<HomeProps> = () => {
  const { t } = useTranslation();
  const onSubmit = async (values: any) => {
    dispatch({ type: AppActions.UPDATE_APPLY_FORM_DETAILS, payload: values });
    dispatch({ type: AppActions.SET_LOADING, payload: true });

    try {
      // TODO - Call api
      dispatch({ type: AppActions.SET_LOADING, payload: false });
      navigate("/thank-you");
    } catch (error) {
      dispatch({ type: AppActions.SET_LOADING, payload: false });
    }
  };

  const {
    state: {
      loading,
      data: { applyFormDetails },
    },
    dispatch,
  } = useContext(AppCtx);
  const navigate = useNavigate();

  useEffect(() => {
    if (Config.TokenHasExpired) {
      navigate("/token-expired");
    } else if (Config.CandidateHasSubmittedData) {
      navigate("/thank-you");
    }
  }, [navigate]);

  return (
    <Page hideHeader enforceDirection="column">
      <Box width={"100%"} boxSizing={"border-box"}>
        <DynamicForm schema={schema} onSubmit={onSubmit} />
      </Box>
      <Box mt={2} mb={2} />
    </Page>
  );
};

export default Home;
