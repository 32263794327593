import { FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { formWidth } from "../../theme";
import Page from "../../components/Page/Page";
import { AppCtx } from "../../store/store";

export type ThankYouDetailsProps = {};
const ThankYou: FC<ThankYouDetailsProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {
      data: { applyFormDetails },
    },
  } = useContext(AppCtx);

  useEffect(() => {
    if (!applyFormDetails) {
      navigate("/");
    }
  }, [applyFormDetails, navigate]);

  return (
    <Page
      title={t("pages.thankYou.title")}
      subtitle={t("pages.thankYou.subtitle")}
      titleWidth={formWidth}
      enforceDirection="column"
      centered
    >
      <Box mt={2} mb={2} />
      <Box mt={2} mb={2} />
    </Page>
  );
};

export default ThankYou;
