import { FunctionComponent } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import ThankYou from "../pages/ThankYou/ThankYou";
import TokenExpired from "../pages/TokenExpired/TokenExpired";

const Index: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/token-expired" element={<TokenExpired />} />
    </Routes>
  );
};

export default Index;
