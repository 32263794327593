import React, { createContext, useReducer } from "react";

export enum AppActions {
  UPDATE_LANGUAGE = "UPDATE_LANGUAGE",
  UPDATE_APPLY_FORM_DETAILS = "UPDATE_APPLY_FORM_DETAILS",
  SET_LOADING = "SET_LOADING",
}

export type PackagesState = number | null;

export interface AppAction {
  type: AppActions;
  payload: any;
}

export interface ICompanyIndustries {
  code: number;
  name: string;
}

export interface InitialAppState {
  language: string;
  loading: boolean;
  data: {
    applyFormDetails: any;
  };
}

export const initialState: InitialAppState = {
  language: "EN",
  loading: false,
  data: {
    applyFormDetails: {},
  },
};

export const AppCtx = createContext<{
  state: InitialAppState;
  dispatch: React.Dispatch<AppAction>;
}>({ state: initialState, dispatch: () => null });

export const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(
    (state: InitialAppState, action: AppAction): InitialAppState => {
      switch (action.type) {
        case AppActions.UPDATE_LANGUAGE:
          return {
            ...state,
            language: action.payload as string,
          };
        case AppActions.UPDATE_APPLY_FORM_DETAILS:
          return {
            ...state,
            data: {
              ...state.data,
              applyFormDetails: action.payload as any,
            },
          };
        case AppActions.SET_LOADING:
          return {
            ...state,
            loading: action.payload as boolean,
          };
        default:
          throw new Error();
      }
    },
    initialState
  );

  return (
    <AppCtx.Provider value={{ state, dispatch }}>{children}</AppCtx.Provider>
  );
};
